import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { getProducts } from "./queries/beer";
import { CartType } from "./stores/cartStore";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}


export const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay))

export type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
    ? ElementType
    : never;


export type ServerResponse = {
    type: "success" | "error",
    message: string
}

export type ClientProduct = ArrElement<Awaited<ReturnType<typeof getProducts>>>


export function formatDate(date: Date, hour: boolean = true) {
    let format: Record<string, "2-digit"> = {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
    }
    if (hour) {
        format = {
            ...format,
            minute: "2-digit",
            hour: "2-digit",
        }
    }
    const formattedDate = new Intl.DateTimeFormat('it-IT',
        format)
        .format(date)
    return formattedDate
}

export function centsToEuros(cents: number) {
    const str = (cents / 100).toFixed(2).replace(".", ",")
    return str
}


// export function getShipmentCost(productsTotalCents: number) {
//     const freeShippingCents = 4400
//     const standardCost = 600
//     const shipmentCost = productsTotalCents >= freeShippingCents ? 0 : standardCost
//     return shipmentCost
// }


export const adminOrderStatusToText = {
    "paymentPending": "In attesa di pagamento",
    "packing": "In attesa",
    "sent": "Spedito",
    "complete": "Completo",
    "canceled": "Annullato"
}


export const orderStatusToText = {
    "paymentPending": "In attesa di pagamento",
    "packing": "In preparazione",
    "sent": "Spedito",
    "complete": "Completo",
    "canceled": "Annullato"
}

export const paymentStatusToText = {
    "processing": "In attesa",
    "complete": "Completo",
    "failed": "Fallito"
}


export const shipmentStatusToText = {
    "pending": "In attesa",
    "sent": "Spedito",
    "received": "Completa",
    "canceled": "Cancellato"
}

export function trackingUrl(trackingCode: string) {

    const trackUrl = `https://www.gls-italy.com/?option=com_gls&view=track_e_trace&mode=search&numero_spedizione=${trackingCode}&tipo_codice=collo`
    return trackUrl
}


